<template>
  <div class="mx-auto auth-wrapper auth-v1 px-2">
    <div class="mx-auto auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">Stories</h2>
        </b-link>

        <b-card-title class="mb-1"> Recuperación de cuenta </b-card-title>
        <b-card-text class="mb-2"> Restablecé tu password </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm" #default="{ invalid }">
          <b-form method="POST" class="auth-reset-password-form mt-2" @submit.prevent="validationForm">
            <!-- password -->
            <b-form-group label="New Password" label-for="reset-password-new">
              <validation-provider #default="{ errors }" name="reset-password-new" rules="required|min:5">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="password1ToggleIcon" @click="togglePassword1Visibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <validation-provider #default="{ errors }" name="reset-password-confirm" rules="required|min:5">
              <b-form-group label-for="reset-password-confirm" label="Confirm Password">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="password2ToggleIcon" @click="togglePassword2Visibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <div v-if="msg" class="text-center">
                  <b style="color: red"> Las contraseñas no coinciden</b>
                </div>
              </b-form-group>

              <!-- submit button -->
              <b-button block type="submit" :disabled="invalid" variant="primary"> Set New Password </b-button>
            </validation-provider>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton } from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import { capitalizeFirstLetter } from '../../util/util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BInputGroupAppend
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      same: false,
      // validation
      required,
      msg: false,
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password'
    }
  },
  computed: {
    email() {
      return this.$store.state.userStore.user.email
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          if (this.cPassword === this.password) {
            this.same = true
            this.$store
              .dispatch('userStore/recoveryPassChange', { email: this.email, password: this.password })
              .then(() => {
                this.$router.push({ name: 'home' }).catch(() => {})
                this.$noti.info(
                  `Bienvenid@ de nuevo a Stories ${
                    this.$session.user.fullname ? capitalizeFirstLetter(this.$session.user.fullname.split(' ')[0]) : ''
                  } !!!`
                )
              })
              .catch(err => this.$noti.error(err.toString()))
          } else {
            this.msg = true
          }
        }
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    !store.state.userStore.user.email ? next({ name: 'login' }) : next()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
